// external
import { useEffect, useState, FormEvent } from "react";
//
// helpers
import { isErrorWithMessage } from "@/utils/errors";
import clsx from "clsx";
import { createHash } from "crypto";
import dayjs from "dayjs";
import Link from "next/link";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useDispatch, useSelector } from "react-redux";

// store
import { setIsAuthenticated } from "@/components/auth/slice";
// components
import Button from "@/components/button";
import Input from "@/components/input";

// helpers
import {
  getCookie,
  getLoginRedirectUrl,
  parseJwt,
  unsetLoginRedirectUrl
} from "@/utils/cookies";

// constants
import { PAGES, STYLES } from "@/globals/constants";
import {
  BACKEND_HOST,
  BFF_HOST,
  BFF_PORT,
  BFF_PROTOCOL
} from "@/globals/constants/environment";

import { Cookie } from "@/store/services/user";
import { RootState } from "@/store/store";

// styles

import Icon from "../icons";
import styles from "./styles.module.scss";

function ErrorMessage({ message }: { message: string }) {
  return <div className={clsx(styles.ErrorMessage)}>{message}</div>;
}

export default function Login() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [errorMsg, setErrorMsg] = useState<string>("");

  const { sessionInfo, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const submitLoginForm = async (e: FormEvent<HTMLFormElement>) => {
    setErrorMsg("");
    e.preventDefault();

    // Parse login parameters from form
    const form = e.currentTarget;
    const formData = new FormData(form);
    const { username, pswrd: password } = Object.fromEntries(
      formData.entries()
    );

    // Hash password; plaintext passwords never reach our backend
    let passwordHash = createHash("sha512")
      .update(password.toString())
      .digest("hex");

    // Submit login request to backend
    try {
      const res = await fetch(
        `${BFF_PROTOCOL}://${BFF_HOST}:${BFF_PORT}/api/users/login`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: username.toString(),
            password: passwordHash
          })
        }
      );
      if (res.status !== 200) {
        setErrorMsg("invalid username and/or password");
        return;
      }

      // parse X-Pario-Auth token
      const cookie = getCookie("X-Pario-Auth");
      if (cookie === undefined) {
        setErrorMsg("Something went wrong!");
        console.error("failed to retrieve authentication token after login");
        return;
      }

      dispatch(setIsAuthenticated(true));

      const sessionInfo = parseJwt(cookie) as Cookie;
      posthog.identify(`${sessionInfo.practice_id}/${sessionInfo.user_id}`, {
        user_id: sessionInfo.user_id,
        practice: sessionInfo.practice_id,
        email: sessionInfo.email,
        first: sessionInfo.first,
        last: sessionInfo.last,
        is_admin: sessionInfo.is_admin,
        is_biller: sessionInfo.is_biller,
        is_ma: sessionInfo.is_ma,
        is_patient: sessionInfo.is_patient,
        is_provider: sessionInfo.is_provider,
        perms: sessionInfo.perms,
        suffix: sessionInfo.suffix,
        title: sessionInfo.title,
        version: sessionInfo.version
      });
    } catch (error) {
      if (isErrorWithMessage(error)) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(
          "Something went wrong on our end — please try again later!"
        );
        console.error(
          "unhandled error during login",
          JSON.stringify(error, null, 4)
        );
      }
    }
  };

  // If cookie_value URL parameter is present, set it as the X-Pario-Auth cookie
  // and redirect to the home page
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cookieValue = urlParams.get("cookie_value");

    if (cookieValue) {
      if (BACKEND_HOST === "localhost") {
        document.cookie = `X-Pario-Auth=${cookieValue};`;
      } else {
        document.cookie = `X-Pario-Auth=${cookieValue}; domain=pariohealth.com; path=/; SameSite=Lax`;
      }
      dispatch(setIsAuthenticated(true));
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // navigate to url that client was attempting to reach
      const nextUrl = getLoginRedirectUrl();
      router.push(nextUrl === undefined ? PAGES.HOME : nextUrl);
      unsetLoginRedirectUrl();
    }
  }, [isAuthenticated]);

  // Inside your useEffect
  useEffect(() => {
    const updateIntercomSettings = () => {
      if (sessionInfo) {
        // @ts-ignore - Again, window doesn't have these types
        window.intercomSettings = {
          name: sessionInfo.first + " " + sessionInfo.last,
          created_at: dayjs().unix(),
          user_id: `${sessionInfo.user_id}`,
          email: sessionInfo.email,
          company: {
            id: `${sessionInfo.practice_id}`,
            // TODO: get practice name from cookie
            // placeholder for now
            name: ""
          }
        };
      }
    };

    updateIntercomSettings(); // Call once on mount

    const sessionInfoStr = JSON.stringify(sessionInfo);
    sessionStorage.setItem("sessionInfo", sessionInfoStr);

    // Add event listener to update intercom settings on storage change
    window.addEventListener("storage", updateIntercomSettings);

    return () => {
      window.removeEventListener("storage", updateIntercomSettings);
    };
  }, [sessionInfo]);

  return (
    // login card
    <div className={styles.Login}>
      <div className={styles.card}>
        {/* sign in message */}
        <div className={styles.icon}>
          <Icon svg="log_in" width={16} height={16} />
        </div>
        <h3 className="tMd light">Welcome back!</h3>
        <div className="t4 xLight">Please sign in</div>

        {errorMsg && <ErrorMessage message={errorMsg} />}

        <form onSubmit={submitLoginForm}>
          {/* username input field */}
          <Input
            label="Username / Email"
            type="text"
            id="username"
            name="username"
            required
          />
          {/* password input field */}
          <Input
            label="Password"
            type="password"
            id="pswrd"
            name="pswrd"
            required
          />
          <div className={styles.buttons}>
            <Button type="submit" style={STYLES.FULL_WIDTH}>
              Sign In
            </Button>
          </div>

          <div className={styles.centered}>
            <Link href={PAGES.RESETREQUEST}>Forgot your password?</Link>
          </div>
        </form>
      </div>
    </div>
  );
}
