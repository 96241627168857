import axios from "axios"

export interface ErrorWithName {
    name: string;
}

function isErrorWithName(error: unknown): error is ErrorWithName {
    return (
        typeof error === "object" &&
        error !== null &&
        "name" in error &&
        typeof (error as Record<string, unknown>).name === "string"
    );
}


export interface ErrorWithMessage {
    message: string;
}

export function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
    return (
        typeof error === "object" &&
        error !== null &&
        "message" in error &&
        typeof (error as Record<string, unknown>).message === "string"
    );
}

export interface ErrorWithStack {
    stack: string;
}

function isErrorWithStack(error: unknown): error is ErrorWithStack {
    return (
        typeof error === "object" &&
            error !== null &&
            "stack" in error &&
            typeof (error as Record<string, unknown>).stack === "string"
    );
}

export type ErrorWithStatusCode = Error & {
    statusCode?: number;
}


export function getErrorWithStatusCode(error: unknown): ErrorWithStatusCode {
    const response: ErrorWithStatusCode = {
        name: "N/A",
        message: "N/A",
    };

    if (isErrorWithName(error)) {
        response.name = error.name;
    }

    if (isErrorWithMessage(error)) {
        response.message = error.message;
    }

    if (isErrorWithStack(error)) {
        response.stack = error.stack;
    }

    if (axios.isAxiosError(error)) {
        response.statusCode = error.status;
    }

    return response;
}
