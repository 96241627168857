//* Pario Login Page **/

/* External */
import { ReactElement } from "react";
import Head from "next/head";
import clsx from "clsx";

/* Components */
import Layout from "@/components/layout";
import Login from "@/components/auth/login";

/* Constants */
import { LAYOUTS } from "@/globals/constants";

/* Styles */
import styles from "@/styles/Page.module.scss";

export default function LoginPage() {
  return (
    <>
      <Head>
        <title>Log in to Pario</title>
        <meta
          name="description"
          content="Log in to platform for women's health practitioners"
        />
      </Head>
      <div className={clsx(styles.Page)}>
        {/* Section 1 */}
        <section className={clsx(styles.secondary)}>
          <Login />
        </section>
      </div>
    </>
  );
}

// Custom layout for login page
LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <Layout type={LAYOUTS.LOGIN}>{page}</Layout>;
};
